export const FirebaseConfig = {
	"projectId": "gess-transport-310e5",
	"appId": "1:670917402700:web:a0fb0aabeca5cddef154e9",
	"databaseURL": "https://gess-transport-310e5-default-rtdb.firebaseio.com",
	"storageBucket": "gess-transport-310e5.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDqOoxHY58IB7wB0jOs68JZRNB75ER5Ujw",
	"authDomain": "gess-transport-310e5.firebaseapp.com",
	"messagingSenderId": "670917402700",
	"measurementId": "G-0LXTQ6DKYK"
};